import React from "react";

// components
import DownForMaintenanceIcon from "@/components/icons/DownForMaintenanceIcon";
import Button from "@/shared/Button";

// assets
import styles from "@/assets/styles/containers/down-for-maintenance.module.scss";


const DownForMaintenance = ({ userType }: { userType?: string }) => {

  const getButtonRedirectLink = () => {
    switch (userType) {
      case "hirer":
        return "/go-search";
      case "landlord":
        return "/locations";
      default:
        return "/";
    }
  }

  return (
    <div className={styles["locl-down-for-maintenance"]} data-testid="locl-down-for-maintenance">
      <div className={styles["locl-down-for-maintenance__icon"]}>
        <DownForMaintenanceIcon/>
      </div>
      <div className={styles["locl-down-for-maintenance__page-content"]}>
        <h1 className={styles["locl-down-for-maintenance__page-content__title"]}>
          We'll be back
        </h1>
        <p className={styles["locl-down-for-maintenance__page-content__description"]}>
          Our site is temporarily unavailable. We'll have things up and running again in a flash!
        </p>
        <Button
          to={getButtonRedirectLink()}
          type="primary"
        >
          Return
        </Button>
      </div>
    </div>
  );
};

export default DownForMaintenance;
