export const reportErrorToSentry = async (error: Error) => {
  const Sentry = await import("@sentry/nextjs");
  Sentry.init({ dsn: process.env.NEXT_PUBLIC_SENTRY_DSN });

  Sentry.captureException(error);

  if (process.env.SENTRY_ENVIRONMENT !== "production") {
    // eslint-disable-next-line no-console
    console.log("error", { error });
  }
}
